const appColumns = [
  // {
  //   key: 'DateStats',
  //   label: 'Date',
  //   show: true,
  //   headerTitle: 'Date',
  //   sortable: true,
  //   thStyle: 'width:90px',
  //   summed: false,
  // },
  {
    key: 'Id',
    label: 'ID',
    show: true,
    headerTitle: 'ID',
    sortable: true,
    thStyle: 'width:70px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'Bundle',
    label: 'Bundle',
    show: true,
    headerTitle: 'Bundle',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Name',
    label: 'App Name',
    show: true,
    headerTitle: 'App Name',
    sortable: true,
    summed: false,
  },
  {
    key: 'Store',
    label: 'Store',
    show: true,
    headerTitle: 'Store',
    thStyle: 'width:130px',
    tdClass: 'text-center',
    sortable: true,
    summed: false,
  },
  {
    key: 'StoreUrl',
    label: 'Store URL',
    show: true,
    headerTitle: 'Store URL',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'StoreBundle',
    label: 'Store Bundle',
    show: true,
    headerTitle: 'Store Bundle',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Hostname',
    label: 'Hostname',
    show: true,
    headerTitle: 'Hostname',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'AppAdsTxtCount',
    label: 'app-ads.txt Records',
    show: true,
    headerTitle: 'app-ads.txt Records',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    summed: false,
    formatter: '$formatNumber',
    thStyle: 'width:160px',
  },
  {
    key: 'Ivt',
    label: 'IVT',
    show: true,
    headerTitle: 'IVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:80px',
  },
  {
    key: 'Sivt',
    label: 'SIVT',
    show: true,
    headerTitle: 'SIVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:80px',
  },
  {
    key: 'DelistedCount',
    label: 'Delisted Count',
    show: true,
    headerTitle: 'Delisted Count',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:130px',
  },
  {
    key: 'BundleMismatchCount',
    label: 'Bundle Mismatch Count',
    show: true,
    headerTitle: 'Bundle Mismatch Count',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:190px',
  },
]
export default appColumns
